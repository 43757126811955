$current-page-theme: voices;
@import "../news/entryPage";
@import "../../utils/all";
@import "../../modules/voices/trending";

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  25%{
    background-position: 50% 100%;
  }

  50% {
    background-position: 100% 50%;
  }

  75% {
    background-position: 50% 0%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.news.voices {
  .entry {
    &__content-and-right-rail-container {
      @include respond-above(sm-desktop) {
        padding-right: 0;
      }
    }
    &-head-inner-container {
      display: flex;
      align-items: stretch;
      gap: 15px;
      margin: 0 auto;
      flex-direction: column;
      &--opinion-vip-writer {
        @include respond-above(sm-desktop) {
          flex-direction: column;
        }
      }
      @include respond-above(max) {
        padding-left: 80px;
        padding-right: 80px;
      }
      &--mobile {
        .entry__top-media .cli-image__source-wrapper {
          border-bottom: none;
        }
      }

      @include respond-above(sm-desktop) {
        margin: 0 32px;
        flex-direction: row;
      }

      @include respond-above(bg-desktop) {
        margin: 0 auto;
        max-width: map-get($breakpoints, sm-desktop);
      }

      @include respond-above(max) {
        max-width: map-get($breakpoints, bg-desktop);
      }
    }
    &__header {
      margin-bottom: 0;
      .top-header {
        @include respond-above(sm-desktop) {
          grid-row: 2;
        }
      }
      .dek {
        @include respond-above(sm-desktop) {
          grid-row: 3;
        }
      }
      .bottom-header {
        @include respond-above(sm-desktop) {
          grid-row: 4;
        }
        &__left {
          @include respond-above(sm-desktop) {
            margin-top: 9px;
          }
          .share-bar--header {
            margin-bottom: 30px;
            display: block;

            @include respond-above(sm-desktop) {
              margin-bottom: 0;
            }
          }

          .social-buttons {
            &--header {
              display: inline;
            }

            &__icons {
              padding-bottom: 0;
              @include respond-above(sm-desktop) {
                justify-content: center;
              }
            }
          }
        }
      }
      .entry__byline {
        color: $voices-purple;
        line-height: 18px;
        font: {
          family: $proxima-nova;
          size: rem-calc(16px);
          weight: 700;
        }

        &:before {
          content: none;
        }

        a {
          color: $voices-purple;
        }
      }
      &--has-top-media {
        margin-top: 0;
        margin-bottom: 0;
        @include respond-above(sm-desktop) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 32px;
          grid-template-rows: 1fr auto auto auto 1fr;
          align-items: center;
          .headline {
            line-height: rem-calc(48px);
            font-size: rem-calc(40px);
          }
          .bottom-header {
            .social-buttons {
              &__icons {
                @include respond-above(sm-desktop) {
                  justify-content: flex-start;
                }
              }
            }
            &__left {
              @include respond-above(sm-desktop) {
                margin-top: 0;
              }
            }
          }
        }
      }
      &--no-top-media {
        margin-bottom: 0;
        margin-top: 0;
        @include respond-above(sm-desktop) {
          margin-top: 26px;
          margin-bottom: 56px;
        }
      }
    }

    &__top-media {
      order: 1;
      max-width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
      background-color: $white;
      @include respond-above(sm-desktop) {
        background-color: transparent;
        grid-column: 2;
        grid-row: 1 / span 5;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      .cli-image__source-wrapper {
        padding: 0 30px 0 30px;
        min-width: 100%;
        max-width: 544px;
        min-height: auto;
        @include respond-above(md-tablet) {
          padding: 0;
        }
      }
    }
    &__top-media--image {
      background-color: $voices-light-purple;
      .cli-image__credit {
        margin-bottom: 0;
      }
      .cli-image__spacer {
        height: 12px;
      }
    }
    &__top-media--embed {
      width: calc(100% + 60px);
      @include respond-above(sm-desktop) {
        width: 100%;
      }
    }
    &__content {

      &-list {
        color: $black-eel;
        font: {
          family: $sourceSerif;
          size: rem-calc(18px);
          weight: 400;
        }
      }
    }
  }

  .cli {
    &-text {
      font-family: $proxima-nova;
      &:not(.cli-support-huffpost) {
        @include respond-above(sm-desktop) {
          max-width: 640px;
          margin-right: 0;
        }
      }
      &.cli-support-huffpost {
        @include respond-above(bg-desktop) {
          margin-right: 52px;
        }
      }
      p > span.first-three-words {
        text-transform: uppercase;
        font-weight: bold;
      }

      //these have to be in this order for the effect to work.
      a:link {
        color: $voices-purple;
        text-decoration: underline;
        box-shadow: none;
      }
      a:visited {
        color: $voices-purple;
        text-decoration: underline;
        box-shadow: none;
      }
      @include non-amp {
        a:hover {
          color: $voices-purple-500;
          text-decoration: underline;
          box-shadow: none;
        }
      }
      a:active {
        color: $voices-purple-500;
        text-decoration: underline;
        box-shadow: none;
      }
    }
    &-listicle {
      @include respond-above(sm-desktop) {
        max-width: 640px;
        margin-right: 0;
        margin-left: auto;
      }
    }
    &-image {
      text-align: center;

      &__source-wrapper {
        border-bottom: none;

        @include respond-above(sm-desktop) {
          min-height: 35px;
        }
      }

      &__caption {
        margin-bottom: 3px;
      }

      &__credit,
      &__caption {
        display: block;
        text-align: left;
        padding: 0;
        margin-top: 0;
      }
    }
  }
}


.above-entry-content{
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #39194A 0%, #8F070F 23.3%, #AD1A06 46.62%, #8F070F 69.94%, #39194A 93.27%, #541438 100%);
  animation: gradient 20s ease infinite;
  background-size: 400% 400%;
  @include respond-above(bg-tablet){
    height: 35px;
  }


  .voices-banner{
    font-size: rem-calc(18px);
    white-space: nowrap;
    text-decoration: none;
    font-family: $sporting;
    font-weight: bold;
    padding-top: 4px;
    line-height: rem-calc(22px);
    color: $voices-light-purple;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    @include respond-above(bg-tablet){
      font-size: rem-calc(26px);
      line-height: rem-calc(29px);
    }
  }
}




