@import "../../utils/all";

.rail--right {
  .zone--trending {
    background-color: $white;
    padding-left: 0;
    padding-right: 0;
    .zone__title {
      @extend %h2-entry-voices;
    }
    .zone__content {
      min-height: 680px; // approximate guess -- helps with CLS
    }
    .card {
      display: flex;
      width: 100%;
      max-width: 100%;
      flex-direction: row;
      border-bottom: 1px solid $gainsboro-gray;
      margin: 24px 0 0;
      padding-bottom: 24px;
      &__text {
        justify-content: start;
      }
      &__headline {
        text-decoration: none;
        &__text {
          width: 100%;
          flex: initial;
          min-height: 50px;
          color: $black;
          font-family: $sporting-bold;
          font-style: normal;
          font-weight: 700;
          font-size: rem-calc(14px);
          line-height: rem-calc(20px);
          letter-spacing: -0.03em;
        }
      }

      &__label--bottom {
        text-align: center;
        margin-top: 10px;
      }

      &__image__link {
        margin-right: 16px;
        width: 92px;
      }
    }
  }
}
