@import "../../../utils/all";
@import "header-mixins";

@include critical {
  .entry__header .timestamp {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    @include respond-above(bg-tablet) {
      flex-direction: row;
    }
    @include theme-scoped(life) {
      @include header-invert-color($white);
      font-family: $montserrat;
      font-weight: 300;
      font-size: rem-calc(11px);
      line-height: 1.91;
    }

    @include theme-scoped(news) {
      font-family: $proxima-nova;
      font-weight: normal;
      color: $med-gray;
      font-size: rem-calc(14px);
      line-height: rem-calc(16px);

      @include respond-above(sm-desktop) {
        justify-content: left;
        font-size: rem-calc(16px);
        line-height: rem-calc(16px);
      }
    }

    @include theme-scoped(featured-news) {
      font-size: rem-calc(13px);
      line-height: rem-calc(22px);
      letter-spacing: 0.45px;
      color: #636363;
      justify-content: center;

      @include respond-above(sm-desktop) {
        font-size: rem-calc(13px);
        line-height: rem-calc(22px);
      }
    }

    @include theme-scoped(opinion) {
      color: $black-eel;
      line-height: rem-calc(18px);
      font: {
        size: rem-calc(14px);
        family: $publicSans;
        weight: 400;
      }

      .entry-head-inner-container--opinion-vip-writer & {
        @include respond-above(sm-desktop) {
          text-align: center;
          justify-content: center;
        }
      }

      strong {
        font-weight: 700;
      }
    }
    @include theme-scoped(voices) {
      color: $voices-purple;
      line-height: rem-calc(18px);
      flex-direction: column;
      text-align: center;
      font: {
        size: rem-calc(14px);
        weight: 400;
      }

      strong {
        font-weight: 400;
      }
      @include respond-above(bg-tablet) {
        flex-direction: row;
        font-size: rem-calc(16px);
      }
    }
    &__separator {
      display: none;
      margin-left: 5px;
      margin-right: 5px;
      @include respond-above(bg-tablet) {
        display: initial;
      }

      time:first-child {
        margin-bottom: 5px;
      }
    }
  }
  .updated-timestamp {
    min-height: 18px;
  }
}
.entry__header--no-top-media .timestamp {
  @include theme-scoped(voices) {
    @include respond-above(sm-desktop) {
      justify-content: center;
    }
  }
}
.entry__header--has-top-media .timestamp {
  @include theme-scoped(voices) {
    @include respond-above(sm-desktop) {
      justify-content: left;
    }
  }
}
